import React from 'react';
import { Grid } from '@material-ui/core';
import PeopleDescriptionItem from '../PeopleDescriptionItem';
import './styles.css';

const PeopleDescriptionList = ({ peopleDescription }) => (
  <Grid container className="section-people-description">
    {
        peopleDescription.map(({ title, description, sizeMs }, i) => (
          <PeopleDescriptionItem
            title={title}
            description={description}
            sizeMs={sizeMs}
            key={i}
          />
        ))
      }
  </Grid>
);

export default React.memo(PeopleDescriptionList);
