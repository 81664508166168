const TIMEZONES_LIST = {
  pacific: [
    { country: 'America/Los_Angeles', description: 'Pacific|Time Zone' },
  ],
  mountain: [
    // no standard time
    { country: 'America/Boise', description: 'Mountain|Time Zone' },
    { country: 'America/Denver', description: 'Mountain|Time Zone' },
    // standard time
    { country: 'America/Creston', description: 'Mountain|Time Zone' },
    { country: 'America/Dawson_Creek', description: 'Mountain|Time Zone' },
    { country: 'America/Fort_Nelson', description: 'Mountain|Time Zone' },
    { country: 'America/Phoenix', description: 'Mountain|Time Zone' },
  ],
  central: [
    // no standard time
    { country: 'America/Chicago', description: 'Central|Time Zone' },
    { country: 'America/Indiana/Knox', description: 'Central|Time Zone' },
    { country: 'America/Indiana/Tell_City', description: 'Central|Time Zone' },
    { country: 'America/Menominee', description: 'Central|Time Zone' },
    { country: 'America/North_Dakota/Beulah', description: 'Central|Time Zone' },
    { country: 'America/North_Dakota/Center', description: 'Central|Time Zone' },
    { country: 'America/North_Dakota/New_Salem', description: 'Central|Time Zone' },
    // standard time
    { country: 'America/Costa_Rica', description: 'Central|Time Zone' },
    { country: 'America/Belize', description: 'Central|Time Zone' },
    { country: 'America/El_Salvador', description: 'Central|Time Zone' },
    { country: 'America/Guatemala', description: 'Central|Time Zone' },
    { country: 'America/Managua', description: 'Central|Time Zone' },
    { country: 'America/Regina', description: 'Central|Time Zone' },
    { country: 'America/Swift_Current', description: 'Central|Time Zone' },
    { country: 'America/Tegucigalpa', description: 'Central|Time Zone' },
  ],
  eastern: [
    // no standard time
    { country: 'America/Detroit', description: 'Eastern|Time Zone' },
    { country: 'America/Indiana/Indianapolis', description: 'Eastern|Time Zone' },
    { country: 'America/Indiana/Marengo', description: 'Eastern|Time Zone' },
    { country: 'America/Indiana/Petersburg', description: 'Eastern|Time Zone' },
    { country: 'America/Indiana/Vevay', description: 'Eastern|Time Zone' },
    { country: 'America/Indiana/Vincennes', description: 'Eastern|Time Zone' },
    { country: 'America/Indiana/Winamac', description: 'Eastern|Time Zone' },
    { country: 'America/Kentucky/Louisville', description: 'Eastern|Time Zone' },
    { country: 'America/Kentucky/Monticello', description: 'Eastern|Time Zone' },
    { country: 'America/New_York', description: 'Eastern|Time Zone' },
    // standard time
    { country: 'America/Panama', description: 'Eastern|Time Zone' },
    { country: 'America/Jamaica', description: 'Eastern|Time Zone' },
    { country: 'America/Atikokan', description: 'Eastern|Time Zone' },
  ],
  latinAmerica: [
    { country: 'Bolivia', description: 'Bolivian|Time Zone' },
    { country: 'America/Argentina/Buenos_Aires', description: 'Uruguay, Argentina Time Zone' },
  ],
};

const getTimeZonesList = () => {
  const {
    pacific: [losAngelesCountry],
    mountain: [crestonCountry],
    central: [costaRicaCountry],
    eastern: [panamaCountry],
    latinAmerica: [boliviaCountry],
  } = TIMEZONES_LIST;
  const mergedTimezones = [
    losAngelesCountry,
    crestonCountry,
    costaRicaCountry,
    panamaCountry,
    boliviaCountry,
  ];
  return mergedTimezones.map(({ country, description }) => ({
    name: country, description, offset: 0, time: '',
  }));
};

const APTITUDE_LIST = [
  {
    id: 0,
    message: 'Latin America and North America have had cultural closeness for centuries and the affinity between them tends to grow even more through permanent interaction, integration and migration on both directions. This alignment promotes greater understanding and a more effective working environment.',
    currentText: 'Similar|Culture.',
    clickedText: ' Towards natural|understanding.',
    habilities: [
      [
        {
          id: 0,
          name: 'communication',
          xs: 12,
          ms: 12,
          hasMargin: true,
        },
      ],
      [{
        id: 1,
        name: 'lifestyle',
        xs: 6,
        ms: 6,
        hasMargin: false,
      },
      {
        id: 2,
        name: 'norms',
        xs: 6,
        ms: 6,
        hasMargin: false,
      },
      ],
      [
        {
          id: 3,
          name: 'work habits',
          xs: 12,
          ms: 12,
          hasMargin: true,
        },
      ],
    ],
    selected: true,
  },
  {
    id: 1,
    message: 'Latin America offers real-time service, in which employees can work during standard North American business hours. Besides that, time zone and physical proximity provide a significant advantage over more distant locations. These factors enable to operate more efficiently and in a much more collaborative way.',
    currentText: 'Matching|Time Zone.',
    clickedText: 'Awake when|you are awake.',
    habilities: [
      [
        {
          id: 4,
          name: 'timely fixes',
          xs: 12,
          ms: 12,
          hasMargin: true,
        },
      ],
      [
        {
          id: 5,
          name: 'agile',
          xs: 6,
          ms: 6,
          hasMargin: false,
        },
        {
          id: 6,
          name: 'feedback',
          xs: 6,
          ms: 6,
          hasMargin: false,
        },
      ],
      [
        {
          id: 7,
          name: 'prompt responses',
          xs: 12,
          ms: 12,
          hasMargin: true,
        },
      ],
    ],
    selected: false,
  },
  {
    id: 2,
    message: 'English can be referred to as the lingua franca of computing. Talented professionals, particularly in the IT industry, exhibit English fluency, contributing to establish better communication and understanding between the teams. We select our resources both for the technical skills and their English proficiency.',
    currentText: 'Language|Proficiency.',
    clickedText: 'For effective|communication.',
    habilities: [
      [
        {
          id: 8,
          name: 'understanding',
          xs: 12,
          ms: 12,
          hasMargin: true,
        },
      ],
      [
        {
          id: 9,
          name: 'agreement',
          xs: 6,
          ms: 6,
          hasMargin: false,
        },
        {
          id: 10,
          name: 'simplicity',
          xs: 6,
          ms: 6,
          hasMargin: false,
        },
      ],
      [
        {
          id: 11,
          name: 'good planning',
          xs: 12,
          ms: 12,
          hasMargin: true,
        },
      ],
    ],
    selected: false,
  },
];

export {
  getTimeZonesList,
  APTITUDE_LIST,
};
