import React from 'react';
import { Grid } from '@material-ui/core';
import AbilityItemList from './AbilityItemList';
import './styles.css';

export default ({ habilities, message }) => {
  const [firstSingleAbility, secondComposeAbility, tirdhSingleAbility] = habilities;
  return (
    <Grid container className="ability-component-container">
      <Grid item xs={12} sm={5}>
        <AbilityItemList abilities={firstSingleAbility} />
        <AbilityItemList abilities={secondComposeAbility} />
        <AbilityItemList abilities={tirdhSingleAbility} />
      </Grid>
      <Grid item xs={12} sm={7} className="ability-component-text">
        <p>
          {message}
        </p>
      </Grid>
    </Grid>
  );
};
