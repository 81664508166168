import React from 'react';
import { Grid, Typography, Paper } from '@material-ui/core';
import StaffGridPaper from './StaffGridPaper';
import StaffGridMore from './StaffGridMore';

import './styles.css';

const PAPER_STAFF_LIST = [
  { name: 'Dedicated|Teams' },
  { name: 'Staff|Augmentation' },
  { name: 'Time &|Materials' },
];

export default () => {
  const [dedicatedTeams, staffAugmentation, timeAndMaterials] = PAPER_STAFF_LIST;
  return (
    <div id="how-we-work" className="how-we-work-section">
      <Typography className="how-we-work-title">HOW WE WORK</Typography>
      <Grid container className="how-we-work-container">
        <Grid item xs={12} sm={12} className="operating-text">
          <Typography variant="subtitle1">
            We operate with a
            <b> nearshoring model </b>
            providing services to our customers in nearby countries.
          </Typography>
        </Grid>
        <Grid item xs={12} sm={12} className="organization-text">
          <Paper>
            <Typography variant="subtitle1">Nearshoring happens when an organization decides to transfer work to companies that are less expensive and relatively geographically close.</Typography>
          </Paper>
        </Grid>
        <Grid item xs={12} sm={6} className="company-objective-text-col-1">
          <Typography variant="subtitle1">Our objective is to help your company achieve business success. We provide our international clients with our expertise in different areas related to the software development process.</Typography>
        </Grid>
        <Grid item xs={12} sm={6} className="company-objective-text-col-2">
          <Typography variant="subtitle1">
            How your company will approach working with us depends on your
            <b> specific needs.</b>
            You can choose from any of the following
            <b> ENGAGEMENT MODELS </b>
            , a combination of these or we’ll tailor one for you:
          </Typography>
        </Grid>
      </Grid>
      <Grid container className="section-staff">
        <Grid item xs={12} sm={4}>
          <StaffGridPaper staff={dedicatedTeams} />
        </Grid>
        <Grid item xs={12} sm={4}>
          <StaffGridMore more={staffAugmentation} />
        </Grid>
        <Grid item xs={12} sm={4}>
          <StaffGridPaper staff={timeAndMaterials} />
        </Grid>
      </Grid>
    </div>
  );
};
