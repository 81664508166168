import React from 'react';
import { Fab, Paper } from '@material-ui/core';
import './styles.css';
import Link from 'gatsby-link';

const showTextParts = (title, separator) => title.split(separator);

export default ({ more: { name } }) => {
  const [first, second] = showTextParts(name, '|');

  return (
    <Paper className="paper-staff-more">
      <div className="grid">
        <div className="staff-letter">
          <div>
            <div>
              <span>{first}</span>
              <span>{second}</span>
            </div>
          </div>
        </div>
        <Link to="/staff-augmentation" style={{ textDecoration: 'none' }}>
          <div className="staff-button">
            <Fab variant="extended" size="medium" color="primary">
              MORE
            </Fab>
          </div>
        </Link>
      </div>
    </Paper>
  );
};
