import React from 'react';
import { Grid } from '@material-ui/core';
import './styles.css';

const compareSelectedValueMemo = (prevProp, nextProp) => {
  const { selected: selectedPrevious } = prevProp;
  const { selected: selectedNext } = nextProp;
  if (selectedPrevious === selectedNext) {
    return true;
  }
  return false;
};

const CarouselItem = ({ imgCarousel, imgCarouselText }) => (
  <Grid container className="carousel-container-item">
    <Grid item xs={12} sm={6} className="carousel-item">
      <div>
        <img src={imgCarouselText} alt="1" />
      </div>
    </Grid>
    <Grid item xs={12} sm={6} className="carousel-item">
      <div>
        <img src={imgCarousel} alt="2" />
      </div>
    </Grid>
  </Grid>
);

export default React.memo(CarouselItem,
  (prevProp, nextProp) => compareSelectedValueMemo(prevProp, nextProp));
