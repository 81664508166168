import React from 'react';
import { Paper, Grid } from '@material-ui/core';
import { showTextParts, compareCultureValueMemo } from './helper';
import TextListItem from './TextListItem';
import './styles.css';

const CultureItem = ({
  id, selected, currentText, clickedText, callbackSelected,
}) => {
  const currentArrayText = showTextParts(currentText, '|');
  const clickedArrayText = showTextParts(clickedText, '|');

  const handleClickItem = () => {
    if (!selected) {
      callbackSelected({ id });
    }
  };

  return (
    <>
      <Grid item xs={4} sm={4} className="culture-item-container">
        <Paper onClick={handleClickItem} className={` ${selected ? 'selected' : 'unselected'} culture-paper`}>
          {selected ? (
            <TextListItem
              texts={clickedArrayText}
            />
          ) : <TextListItem texts={currentArrayText} />}
        </Paper>
      </Grid>
    </>
  );
};

export default React.memo(CultureItem,
  (prevProp, nextProp) => compareCultureValueMemo(prevProp, nextProp));
