import imgApplicationDev from '../../../assets/application-development.png';
import imgProjectManagement from '../../../assets/project-management.png';
import imgApplicationMaintenance from '../../../assets/application-maintenance.png';
import imgUiUxDesign from '../../../assets/ui-ux-design.png';
import imgTestingServices from '../../../assets/testing-services.png';

const SERVICES_CONTENT = [
  {
    id: 0,
    title: 'Application Development',
    imgPath: imgApplicationDev,
    sizeColumn: 4,
    selected: false,
    path: '/application-development',
    descriptions: [
      'We offer development, testing, maintenance and reengineering services. We follow the systems development life cycle (SDLC) for all software development and enhancement projects, which simplifies the process and gives us a firm control over the development cycle. Quality Assurance is inherent to all our projects, therefore we ensure that the product works flawlessly.',
      'Adaptive planning, early delivery and continuous improvement are necessary to keep up with the fast pace we see in software and hardware upgrades now-a-days.',
      'We have a business-oriented approach to application development going beyond standard software engineering processes to ensure the best quality in the  products we deliver.',
    ],
    concepts: [
      {
        title: 'Concept Screening',
        content: 'Testing your product ideas to move your business forward: Test of Product, SWOT Analysis, Feasibility Study and other.',
        bgColor: 'bg-concept-normal-gray',
      },
      {
        title: 'Design & Development',
        content: 'Performance measures and tools: Prototype, Storyboard, Feature Delivery, Project Setup and Software Development.',
        bgColor: 'bg-concept-light-gray',
      },
      {
        title: 'Application Support',
        content: 'Enabling business performance and strategy through: Maintenance, Enhancements and Version Control.',
        bgColor: 'bg-concept-light-green',
      },
    ],
  },
  {
    id: 1,
    title: 'Project Management',
    imgPath: imgProjectManagement,
    sizeColumn: 4,
    selected: false,
    path: '/project-management',
    descriptions: [
      'Outsourcing the project management tasks helps organizations which are interested in maintaining their focus on their core competencies. Projects that are not properly managed can have a real negative impact on business performance.',
      'At Nearshore Code we apply management solutions  with an objective, professional approach allowing a faster time to market.',
      'We provide a fresh view to implement the required techniques during the project life cycle, from initiation, through planning, scheduling, monitoring and control, to termination.',
      'Our commitment towards quality reflects on the strong relationships we forge with our clients.',
    ],
    concepts: [
      {
        title: 'AGILE Management',
        content: 'It is applied to deliver software requirements in incremental iterations with greater visibility and continuous feedback, allowing you to react very quickly to bottlenecks and change.',
        bgColor: 'bg-concept-light-green',
      },
      {
        title: 'Project Managers',
        content: 'Providing appropriate project management talent to tackle high impact and time sensitive IT projects. The mixture of technical and soft skills of our managers are the key to success.',
        bgColor: 'bg-concept-normal-gray',
      },
    ],
  },
  {
    id: 2,
    title: 'Application Maintenance',
    imgPath: imgApplicationMaintenance,
    sizeColumn: 4,
    selected: false,
    path: '/application-maintenance',
    descriptions: [
      'Choosing the right strategies and processes to support your software applications will ensure its operations are rationalized and working as expected.',
      'Practically everything runs on software applications, so it’s ever more important to effectively maintain and support them. It is critical to keep your applications up and running, adapting to a changing environment and troubleshooting for any issues identified.',
      'Whether it’s a matter of errors identified, continuous improvement or standard maintenance, our skilled software developers will deal with it and live up to the challenge. The quality of our services is backed up by years of experience providing ongoing maintenance and support.',
    ],
    concepts: [
      {
        title: 'Application Enhancements',
        content: 'Upgrade the capacity of your app to fulfill broader requirements through extensions, technical, functional and ongoing enhancements.',
        bgColor: 'bg-concept-normal-gray',
      },
      {
        title: 'Configuration Management',
        content: 'Stability in the product’s performance, attributes, requirements and operational information, including version control and updates.',
        bgColor: 'bg-concept-light-gray',
      },
      {
        title: 'Troubleshooting & Bug Fixing',
        content: 'Cost-effective and in-time bug identification, fixing bugs with the appropriate techniques and tools.',
        bgColor: 'bg-concept-light-green',
      },
      {
        title: 'Performance Management',
        content: 'Monitoring and measuring relevant metrics to ensure the expected level of service, performance and availability of the application.',
        bgColor: 'bg-concept-dark-gray',
      },
    ],
  },
  {
    id: 3,
    title: 'UI/UX Design',
    imgPath: imgUiUxDesign,
    sizeColumn: 6,
    selected: false,
    path: '/ui-ux-design',
    descriptions: [
      'User eXperience (UX) refers to enhancing the experience and satisfaction the user has beyond simple usability. It includes aspects such as graphics, design, interface, and both physical and manual interaction.',
      'Very often the success of your software product will depend on how user-friendly it is, but it goes beyond the usability of the User Interface (UI).',
      'At Nearshore Code we have a team with years of experience in providing UI/UX development services. The methods and processes we apply aim to produce additional value to your customers and your product through presentation and interactivity.',
    ],
    concepts: [
      {
        title: 'Architecture Planning',
        content: 'Structure is a component as important as content. Applying the right techniques makes the difference in making a digital product valuable.',
        bgColor: 'bg-concept-normal-gray',
      },
      {
        title: 'UX Roadmap Designing',
        content: 'The blueprint can ensure that you will accomplish your research and design goals on time, sticking to your budget and considering all key components.',
        bgColor: 'bg-concept-light-gray',
      },
      {
        title: 'Wireframe Designing',
        content: 'This source of information regarding layouts, visual hierarchy, information architecture and content priority allows to make a faster and smarter design.',
        bgColor: 'bg-concept-light-green',
      },
      {
        title: 'Front-end Development',
        content: 'Applying  the appropriate tools, frameworks and libraries in order to develop a well-designed and scalable product for the client to interact with.',
        bgColor: 'bg-concept-dark-gray',
      },
    ],
  },
  {
    id: 4,
    title: 'Testing Services',
    imgPath: imgTestingServices,
    sizeColumn: 6,
    selected: false,
    path: '/testing-services',
    descriptions: [
      'Is your software sufficiently usable? Does it respond correctly to different inputs? Can you use it in different environments? Will it perform its functions in an adequate time frame? Does it meet the business and technical requirements that originated its design? You need to place this critical validation and verification processes in good hands.',
      'At Nearshore Code, we conduct the necessary software testing to assure the quality of your product and provide the end user a flawless experience. Our QA staff provides integrated software testing services using multiple strategies, techniques and practices. Our experience will help you deliver successful products with minimum risk and reduced investment.',
    ],
    concepts: [
      {
        title: 'Manual Testing',
        content: 'Following rigorous processes during all the phases and in different scenarios to meet adequate quality standards prior to product delivery.',
        bgColor: 'bg-concept-normal-gray',
      },
      {
        title: 'Performance Testing',
        content: 'Responsiveness, stability, scalability, speed, resource usage and reliability are crucial when you talk about website and app performance.',
        bgColor: 'bg-concept-light-gray',
      },
      {
        title: 'Mobile Testing',
        content: 'Testing the app across different mobile devices to ensure a seamless experience for the user and your company to remain competitive.',
        bgColor: 'bg-concept-light-green',
      },
      {
        title: 'Test Automation',
        content: 'Sometimes manual testing is not enough to complete coverage or perform repetitive tasks. We apply testing methodologies that include in-house automation tools and frameworks.',
        bgColor: 'bg-concept-dark-gray',
      },
    ],
  },
];

export { SERVICES_CONTENT };
