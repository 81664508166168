import React, { useState, useEffect, useCallback } from 'react';
import { Grid, Typography } from '@material-ui/core';
import { getTimeZonesList, APTITUDE_LIST } from './helper';
import Cultures from './Cultures';
import ClockItemList from './ClockItemList';
import './styles.css';

export default () => {
  const [aptitudeList, setAptitudeList] = useState(APTITUDE_LIST);
  const [indexList, setIndexList] = useState(0);
  const informationTimeZones = getTimeZonesList();

  const changeSelectedAptitude = useCallback((id) => aptitudeList.map((aptitude) => {
    if (aptitude.id === id) {
      return { ...aptitude, selected: true };
    }
    return { ...aptitude, selected: false };
  }), [aptitudeList]);

  const onHandleClickSelected = useCallback(({ id }) => {
    const updateAptitudeList = changeSelectedAptitude(id);
    setAptitudeList([...updateAptitudeList]);
    setIndexList(id);
  }, [changeSelectedAptitude]);

  useEffect(() => {
    let increment = 0;
    const aptitudeSize = APTITUDE_LIST.length - 1;

    const timer = setInterval(() => {
      if (increment < aptitudeSize) {
        increment += 1;
      } else {
        increment = 0;
      }
      onHandleClickSelected({ id: increment });
      return () => {
        clearTimeout(timer);
      };
    }, 3000);
  }, []);

  return (
    <div className="nearshoring-company-container">
      <Grid container>
        <Grid item xs={12} className="nearshoring-title">
          <Typography variant="h6">
            NEARSHORING WITH US
          </Typography>
          <ClockItemList clocks={informationTimeZones} />
        </Grid>
      </Grid>
      <Cultures
        aptitudes={aptitudeList}
        index={indexList}
        callbackSelected={onHandleClickSelected}
      />
    </div>
  );
};
