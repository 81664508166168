import React from 'react';
import { Grid } from '@material-ui/core';
import { Carousel } from 'react-responsive-carousel';
import CarouselItem from './CarouselItem';
import imgCarouselOne from '../../../../assets/image-carrousel-1.png';
import imgCarouselTextOne from '../../../../assets/text-carrousel-1.png';
import imgCarouselTwo from '../../../../assets/image-carrousel-2.png';
import imgCarouselTextTwo from '../../../../assets/text-carrousel-2.png';
import imgCarouselThree from '../../../../assets/image-carrousel-3.png';
import imgCarouselTextThree from '../../../../assets/text-carrousel-3.png';
import 'react-responsive-carousel/lib/styles/carousel.min.css';
import './styles.css';

const IMAGE_LIST = [
  { imgCarousel: imgCarouselOne, imgCarouselText: imgCarouselTextOne },
  { imgCarousel: imgCarouselTwo, imgCarouselText: imgCarouselTextTwo },
  { imgCarousel: imgCarouselThree, imgCarouselText: imgCarouselTextThree },
];

export default () => (
  <Grid container className="carousel-container">
    <Carousel
      autoPlay={true}
      interval={3000}
      showArrows={true}
      showThumbs={false}
      infiniteLoop={true}
      showStatus={false}
      className="carousel-content"
    >
      {
        IMAGE_LIST.map(({ imgCarousel, imgCarouselText }, i) => (
          <CarouselItem imgCarousel={imgCarousel} imgCarouselText={imgCarouselText} key={i} />
        ))
      }
    </Carousel>
  </Grid>
);
