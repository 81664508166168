import React from 'react';
import { Grid } from '@material-ui/core';
import AbilityItem from './AbilityItem';
import './styles.css';

const AbilityItemList = ({ abilities }) => (
  <Grid container>
    {
        abilities.map(({
          id, name, xs, sm, hasMargin,
        }) => <AbilityItem name={name} xs={xs} sm={sm} hasMargin={hasMargin} key={id} />)
      }
  </Grid>
);

export default React.memo(AbilityItemList);
