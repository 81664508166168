/* eslint-disable react/no-array-index-key */
import React from 'react';
import { Grid } from '@material-ui/core';
import OurValuesItem from '../OurValuesItem';

const OurValuesList = ({ ourValues }) => (
  <Grid container className="our-values-img-section">
    {
        ourValues.map(({ label, imgValue }, i) => (
          <OurValuesItem
            title={label}
            imgValue={imgValue}
            key={i}
          />
        ))
      }
  </Grid>
);

export default React.memo(OurValuesList);
