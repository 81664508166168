const showTextParts = (title, separator) => title.split(separator);
const compareCultureValueMemo = (prevProp, nextProp) => {
  const { id: idPrevious, selected: selectedPrevious } = prevProp;
  const { id: idNext, selected: selectedNext } = nextProp;
  if (idPrevious === idNext && selectedPrevious === selectedNext) {
    return true;
  }
  return false;
};

export {
  showTextParts,
  compareCultureValueMemo,
};
