import React from 'react';
import { Grid } from '@material-ui/core';
import Abilities from './Abilities';
import CultureItemList from './CultureItemList';
import './styles.css';

const Cultures = ({ aptitudes, callbackSelected, index }) => {
  const { message, habilities } = aptitudes[index];
  return (
    <div className="culture-section">
      <Abilities habilities={[...habilities]} message={message} />
      <Grid container className="culture-component-container">
        <CultureItemList aptitudes={aptitudes} callbackSelected={callbackSelected} />
      </Grid>
    </div>
  );
};

export default React.memo(Cultures);
