import React from 'react';
import Bridge from '../../../assets/san-francisco-bridge.png';

import './styles.css';

export default () => (
  <div>
    <img src={Bridge} className="bridge" alt="bridge-img" />
    <p className="green-text">We have been delivering IT outsourcing services and solutions worldwide since 2013.</p>
  </div>
);
