const BUDGET_GROUP = [
  { name: 'Under 50k', value: 'Under 50k' },
  { name: '50k - 75k', value: '50k - 75k' },
  { name: '75k - 150k', value: '75k - 150k' },
  { name: '150k - 250k', value: '150k - 250k' },
  { name: '250k - 500k', value: '250k - 500k' },
  { name: 'Above 500k', value: 'Above 500k' },
];

const NDA_VALUE = [
  { name: 'Yes', value: 'true' },
  { name: 'No', value: 'false' },
];

const PROJECT_NEED_VALUE = [
  { name: 'Build Something New', value: 'Build Something New' },
  { name: 'Improve Existing Project', value: 'Improve Existing Project' },
  { name: 'Extend My Team', value: 'Extend My Team' },
];

const PROJECT_SCOPE_VALUES = [
  { name: 'iOS', value: 'iOS' },
  { name: 'Android', value: 'Android' },
  { name: 'Flutter', value: 'Flutter' },
  { name: 'React Native', value: 'React Native' },
  { name: 'Product Design', value: 'Product Design' },
  { name: 'Website', value: 'Website' },
  { name: 'Backend', value: 'Backend' },
  { name: 'Admin Panel', value: 'Admin Panel' },
];

const REFERRAL_VALUE = [
  { name: 'Google', value: 'Google' },
  { name: 'From a friend', value: 'From a friend' },
  { name: 'From a coworker', value: 'From a coworker' },
  { name: 'Online Advertisement', value: 'Online Advertisement' },
  { name: 'Blog Post', value: 'Blog Post' },
  { name: 'From a network', value: 'From a network' },
];

export {
  BUDGET_GROUP,
  NDA_VALUE,
  PROJECT_NEED_VALUE,
  PROJECT_SCOPE_VALUES,
  REFERRAL_VALUE,
};
