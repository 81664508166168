import momentTimeZone from 'moment-timezone';
import moment from 'moment';

const TIME_FORMAT = 'HH:mm:ss';

const getTimezone = (timezone, timeFormat) => timezone.format(timeFormat);

const getUtcOffSet = (timezone) => timezone.utcOffset() / 60;

const calculateTimeZone = (region) => {
  const { name, description } = region;
  let partnersDateTimeZone;

  let time; let offset;
  const newDescription = description;
  if (name !== 'Bolivia') {
    partnersDateTimeZone = momentTimeZone.tz(name);
    time = getTimezone(partnersDateTimeZone, TIME_FORMAT);
    offset = getUtcOffSet(partnersDateTimeZone);
    return {
      ...region, time, offset, description: newDescription,
    };
  }
  const boliviaDateTimeZone = moment();
  time = getTimezone(boliviaDateTimeZone, TIME_FORMAT);
  offset = getUtcOffSet(boliviaDateTimeZone);
  return {
    ...region, time, offset, description: newDescription,
  };
};

const showTextParts = (title, separator) => title.split(separator);

export {
  calculateTimeZone,
  showTextParts,
};
