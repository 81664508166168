import React from 'react';
import { Grid, Fab } from '@material-ui/core';
import Link from 'gatsby-link';
import PaperInfoContent from './PaperInfoContent';
import CarouselSlider from './CarouselSlider';

import './styles.css';

export default () => (
  <div id="who-we-are" className="info">
    <div className="info-company-section">
      <Grid container className="info-company-container">
        <CarouselSlider />
        <Grid item xs={12} sm={8}>
          <div className="content-info">
            <p>
              We are a software development company that has the commitment to meet our customer’s needs. No matter the complexity of the challenge or technology required, we deliver you high-quality solutions.
            </p>
          </div>
          <div className="content-info">
            <p>
              Being located in South America, we operate without any restriction of
              <b>
                {' '}
                time, distance, culture or language.
                {' '}
              </b>
            </p>
          </div>
          <div className="content-info">
            <div className="more-about-us">
              <Link to="/about" style={{ textDecoration: 'none' }}>
                <Fab variant="extended" color="primary" aria-label="open-modal">
                  MORE ABOUT US
                </Fab>
              </Link>
            </div>
          </div>
        </Grid>
        <Grid item xs={12} sm={4} className="extra-content">
          <PaperInfoContent />
        </Grid>
      </Grid>
    </div>
  </div>
);
