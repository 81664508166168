import React from 'react';
import { Grid, Typography, Fab } from '@material-ui/core';
import CheckCircleOutlineIcon from '@material-ui/icons/CheckCircleOutline';
import ErrorIcon from '@material-ui/icons/Error';
import './styles.css';

export const EmailModal = ({ handleClose, emailSend, userValues }) => {
  const {
    contactUsName,
    contactUsEmail,
    contactUsPhone,
    contactUsWebsite,
    contactUsMessage,
    contactUsBudget,
    contactUsNda,
    contactUsProjectNeed,
    contactUsProjectScope,
    contactUsReferral,
  } = userValues;

  return (
    <Grid container className="email-container">
      <Grid item xs={12} className={`email-alert ${emailSend ? 'sucess-color' : 'error-color'}`}>
        {
          emailSend ? <CheckCircleOutlineIcon fontSize="large" /> : <ErrorIcon fontSize="large" />
        }

      </Grid>
      <Grid item xs={12} className="email-message">
        {
          emailSend
            ? (
              <Typography variant="body1" style={{ color: 'black' }}>
                Thank you for your message. We will get back to you as soon as possible.
              </Typography>
            )
            : (
              <>
                <Typography variant="body1" style={{ color: 'black' }}>
                  Something went wrong, please try again later. If you want to contact us in the meantime, you could send the information below to business@nearshorecode.com
                </Typography>
                <div>
                  <div>
                    Information:
                    <div>
                      name:
                      {' '}
                      {contactUsName}
                    </div>
                    <div>
                      email:
                      {' '}
                      {contactUsEmail}
                    </div>
                    <div>
                      phone:
                      {' '}
                      {contactUsPhone}
                    </div>
                    <div>
                      website:
                      {' '}
                      {contactUsWebsite}
                    </div>
                    <div>
                      message:
                      {' '}
                      {contactUsMessage}
                    </div>
                    <div>
                      nda:
                      {' '}
                      {contactUsNda === 'true' ? 'yes' : 'no'}
                    </div>
                    <div>
                      budget:
                      {' '}
                      {contactUsBudget}
                    </div>
                    <div>
                      projectNeed:
                      {' '}
                      {contactUsProjectNeed}
                    </div>

                    <div>
                      projectScope:
                      {' '}
                      {contactUsProjectScope}
                    </div>
                    <div>
                      referral:
                      {' '}
                      {contactUsReferral}
                    </div>
                  </div>
                </div>
              </>
            )
        }
      </Grid>
      <Grid item xs={12} className="btn-ok">
        <Fab
          variant="extended"
          size="medium"
          color="primary"
          aria-label="go-to-get-in"
          onClick={handleClose}
        >
          OK
        </Fab>
      </Grid>
    </Grid>
  );
};
