import React from 'react';
import { Paper, Typography } from '@material-ui/core';
import World from '../../../../assets/world.png';
import './styles.css';

export default () => (
  <Paper className="paper-info-content">
    <Typography component="p">
      We know you have the greatest IDEAS in mind, but perhaps you don’t have the team to make them come true. We can help you complete your in-house workforce.
    </Typography>
    <div className="img-world">
      <img src={World} alt="world" />
    </div>
  </Paper>
);
