import React, { useEffect, useState } from 'react';
import { Grid, Typography } from '@material-ui/core';
import { calculateTimeZone, showTextParts } from './helper';
import AnalogClock from './AnalogClock';
import './styles.css';

const ClockItem = ({ clock }) => {
  const [fullTimeClock, setFullTimeClock] = useState({
    hoursDegrees: 0,
    minutesDegrees: 0,
    secondsDegrees: 0,
    hours: 0,
    minutes: 0,
    seconds: 0,
    offSet: 0,
    description: '',
  });

  const [descriptions, setDescriptions] = useState([
    '',
    '',
    '',
  ]);

  const [first, second, third] = descriptions;

  const setToHoursDegrees = (hours, minutes) => hours * 30 + minutes / 2;
  const setToMinutesDegrees = (minutes, seconds) => minutes * 6 + seconds / 10;
  const setToSecondsDegrees = (seconds) => seconds * 6;

  useEffect(() => {
    const clockInterval = setInterval(() => {
      const newTime = calculateTimeZone(clock);
      const { time, offSet, description } = newTime;
      const [hours, minutes, seconds] = showTextParts(time, ':').map((item) => +item);
      const newDescriptions = showTextParts(description, '|');

      setDescriptions(newDescriptions);
      setFullTimeClock({
        hoursDegrees: setToHoursDegrees(hours, minutes),
        minutesDegrees: setToMinutesDegrees(minutes, seconds),
        secondsDegrees: setToSecondsDegrees(seconds),
        hours,
        minutes,
        seconds,
        offSet,
        description,
      });
    }, 1000);

    return () => clearInterval(clockInterval);
  });

  return (
    <Grid item xs={6} sm={6} md={3} lg={2} className="last-clock">
      <div className="nearshoring-clock">
        <AnalogClock fullTimeClock={fullTimeClock} />
        <Typography variant="body1" align="center">
          {first}
        </Typography>
        <Typography variant="body1" align="center">
          {second}
        </Typography>
        <Typography variant="body1" align="center">
          {third || ''}
        </Typography>
      </div>
    </Grid>
  );
};

export default React.memo(ClockItem);
