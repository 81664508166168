import React from 'react';
import Header from '../layout/Header/header';
import Footer from '../layout/Footer/footer';
import { Home } from '../components/Home';
import { SEO } from '../shared/seo';

import '../styles/global.css';

// TODO: Home component content should be index.js
//        - Add sentry for Contact Us Form
export default () => (
  <div>
    <SEO />
    <Header />
    <Home />
    <Footer />
  </div>
);
