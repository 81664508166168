import React from 'react';
import Bridge from './Bridge';
import HowWeWork from './HowWeWork';
import People from './People';
import OurServices from './OurServices';
import OurValues from './OurValues';
import NearshoringCompany from './NearshoringCompany';
import InfoCompany from './InfoCompany';
import { ContactUsForm } from './ContactUs';

export const Home = () => (
  <>
    <InfoCompany />
    <Bridge />
    <OurServices />
    <HowWeWork />
    <NearshoringCompany />
    <People />
    <OurValues />
    <ContactUsForm />
  </>
);
