import React from 'react';
import { Grid, Typography, Paper } from '@material-ui/core';
import './styles.css';

const PeopleDescriptionItem = ({ title, description, sizeMs }) => (
  <Grid item xs={12} sm={sizeMs} className="people-description-item">
    <Paper className="paper-description-item">
      <Typography variant="h5" component="h1" className="people-title-item">
        {' '}
        {title}
        {' '}
      </Typography>
      <Typography variant="body1" className="people-description-item">
        {' '}
        {description}
        {' '}
      </Typography>
    </Paper>
  </Grid>
);

export default React.memo(PeopleDescriptionItem);
