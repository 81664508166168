import React from 'react';
import { HourNumbers } from '../../../../../../assets/svg/svg-clock';
import './styles.css';

export default ({ fullTimeClock }) => {
  const { hoursDegrees, minutesDegrees, secondsDegrees } = fullTimeClock;

  const styleHours = {
    transform: `rotateZ(${hoursDegrees}deg)`,
  };

  const styleMinutes = {
    transform: `rotateZ(${minutesDegrees}deg)`,
  };

  const styleSeconds = {
    transform: `rotateZ( ${secondsDegrees}deg)`,
  };
  return (
    <div className="analog-clock-container">
      <div className="analog-clock">
        <div className="clock-content">
          <HourNumbers />
          <div className="indicator hours-indicator" style={styleHours} />
          <div className="indicator minutes-indicator" style={styleMinutes} />
          <div className="indicator seconds-indicator" style={styleSeconds} />
          <div className="indicator-cover" />
        </div>
      </div>
    </div>
  );
};
