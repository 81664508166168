import React from 'react';
import { Grid, Typography } from '@material-ui/core';
import ServiceItemList from './ServiceItemList';
import { SERVICES_CONTENT } from './helper';
import './styles.css';

export default ({ changeRoute }) => {
  const [firstColumn, secondColumn, ThirdColumn, ...restRow] = SERVICES_CONTENT;
  const firstRow = [firstColumn, secondColumn, ThirdColumn];
  return (
    <div className="services">
      <div className="our-services-section">
        <Typography id="our-services" className="title-services">
          OUR SERVICES
        </Typography>
        <Grid container className="container-services-1">
          {
          firstRow.map(({
            title, imgPath, sizeColumn, id, path,
          }) => (
            <ServiceItemList
              title={title}
              imgPath={imgPath}
              sizeColumn={sizeColumn}
              key={id}
              id={id}
              path={path}
              changeRoute={changeRoute}
            />
          ))
        }
        </Grid>

        <Grid container className="container-services-2">
          {
          restRow.map(({
            title, imgPath, sizeColumn, id, path,
          }) => (
            <ServiceItemList
              title={title}
              imgPath={imgPath}
              sizeColumn={sizeColumn}
              key={id}
              id={id}
              path={path}
              changeRoute={changeRoute}
            />
          ))
        }
        </Grid>
      </div>
    </div>
  );
};
