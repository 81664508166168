import imgHonesty from '../../../assets/honesty.png';
import imgIntegrity from '../../../assets/integrity.png';
import imgExcellence from '../../../assets/excellence.png';
import imgExperience from '../../../assets/experience.png';
import imgRespect from '../../../assets/respect.png';
import imgReliability from '../../../assets/reliability.png';

const OUR_VALUES = [
  { label: 'Honesty', imgValue: imgHonesty },
  { label: 'Integrity', imgValue: imgIntegrity },
  { label: 'Excellence', imgValue: imgExcellence },
  { label: 'Experience', imgValue: imgExperience },
  { label: 'Respect', imgValue: imgRespect },
  { label: 'Reliability', imgValue: imgReliability },
];

export { OUR_VALUES };
