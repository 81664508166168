import React from 'react';
import { Paper } from '@material-ui/core';
import './styles.css';

const showTextParts = (title, separator) => title.split(separator);

export default ({ staff: { name }, split = true }) => {
  const [first, second] = split ? showTextParts(name, '|') : [name, ''];
  return (
    <Paper className="paper-staff-grid">
      <div className="grid">
        <div>
          <span>{first}</span>
          <span>{second}</span>
        </div>
      </div>
    </Paper>
  );
};
