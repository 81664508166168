import React from 'react';
import { Grid, Typography } from '@material-ui/core';
import Link from 'gatsby-link';
import './styles.css';

const showTextParts = (title, separator) => title.split(separator);

const ServiceItemList = ({
  title, imgPath, sizeColumn, id, path,
}) => {
  const [firstTitle, secondTitle] = showTextParts(title, ' ');

  return (
    <div className="service-item-list">
      <Grid item xs={6} sm={sizeColumn} className={`service-item-list item-${id}`}>
        <div className="service-item-list-paper">
          <Link to={path} style={{ textDecoration: 'none' }}>
            <div className="service-item-content">
              <img src={imgPath} alt={title} />
              <Typography variant="body2" align="center">
                {firstTitle}
              </Typography>
              <Typography variant="body2" align="center">
                {secondTitle}
              </Typography>
            </div>
          </Link>
        </div>
      </Grid>
    </div>
  );
};

export default React.memo(ServiceItemList);
