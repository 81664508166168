import React from 'react';
import { Grid, Typography } from '@material-ui/core';
import PeopleDescriptionList from './PeopleDescriptionList';

import './styles.css';

const PEOPLE_DESCRIPTION = [
  {
    title: 'OUR PEOPLE',
    description: 'We have a team in which each individual pours his passion and knowledge into his project and the company’s goals. Our people constantly develop their abilities through training and practice in a comfortable yet challenging environment that fosters creativity and productivity.​',
    sizeMs: 6,
  },
  {
    title: 'FUN & PASSION',
    description: 'Our work environment is relaxed but highly productive as we do our best to deliver top-quality products and services. We provide the necessary infrastructure and opportunities to develop successful teams that show passion for what they do everyday.',
    sizeMs: 6,
  },
];

export default () => (
  <div id="our-people" className="people-component-section">
    <PeopleDescriptionList peopleDescription={PEOPLE_DESCRIPTION} />
    <Grid container className="people-attributes-grid">
      <Typography variant="body1">Other than technical abilities, our people have English fluency and of course, ability to provide remote work effectively.</Typography>
    </Grid>
  </div>
);
