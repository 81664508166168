import React from 'react';
import { Grid } from '@material-ui/core';
import ClockItem from './ClockItem';
import './styles.css';

const ClockItemList = ({ clocks }) => (
  <Grid item xs={12}>
    <Grid container className="nearshoring-items">
      {
        clocks.map((clock, i) => <ClockItem clock={clock} key={i} />)
      }
    </Grid>
  </Grid>
);

export default React.memo(ClockItemList);
