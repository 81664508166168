import React from 'react';
import { Grid, Paper, Typography } from '@material-ui/core';
import { compareValueMemo } from './helper';
import './styles.css';

const AbilityItem = ({
  name, xs, sm, hasMargin,
}) => (
  <Grid item xs={xs} sm={sm} className="ability-item">
    <Paper className={hasMargin ? 'has-margin' : null}>
      <Typography align="center" variant="body1">
        {' '}
        {name}
        {' '}
      </Typography>
    </Paper>
  </Grid>
);

export default React.memo(AbilityItem,
  (prevProp, nextProp) => compareValueMemo(prevProp, nextProp));
