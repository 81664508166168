import React from 'react';
import { Grid, Typography } from '@material-ui/core';
import './styles.css';

const OurValuesItem = ({ imgValue, title }) => (
  <Grid item xs={4} sm={2} className="our-values-image-content">
    <img src={imgValue} alt={title} />
    <Typography variant="h6" align="center">{title}</Typography>
  </Grid>
);

export default React.memo(OurValuesItem);
