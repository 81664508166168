import React from 'react';
import CultureItem from './CultureItem';

const CultureItemList = ({ aptitudes, callbackSelected }) => (
  <>
    {
        aptitudes.map(({
          id, selected, currentText, clickedText,
        }) => (
          <CultureItem
            id={id}
            selected={selected}
            currentText={currentText}
            clickedText={clickedText}
            callbackSelected={callbackSelected}
            key={id}
          />
        ))
      }
  </>
);

export default React.memo(CultureItemList);
