import React from 'react';
import { Grid, Typography } from '@material-ui/core';
import './styles.css';

export const ContactUsContainer = () => (
  <Grid container className="contact-us-section">
    <Grid item xs={12} className="contact-us-title">
      <Typography variant="h5" align="center">
        Contact Us
      </Typography>
    </Grid>
  </Grid>
);
