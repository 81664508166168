import React from 'react';
import { Grid, Typography, Container } from '@material-ui/core';
import { OUR_VALUES } from './helper';
import OurValuesList from './OurValuesList';
import './styles.css';

export default () => (
  <Container maxWidth="lg">
    <Grid container className="our-values-section">
      <Grid item xs={12} className="our-values-title">
        <Typography variant="h5" align="center"> OUR VALUES</Typography>
      </Grid>
      <OurValuesList ourValues={OUR_VALUES} />
    </Grid>
  </Container>
);
