/* eslint-disable guard-for-in */
/* eslint-disable no-restricted-syntax */
import React, { useState } from 'react';
import {
  FormControl, TextField, Fab, Grid, Radio, RadioGroup, FormLabel, Checkbox,
  FormControlLabel, NativeSelect,
} from '@material-ui/core';
import axios from 'axios';
import {
  BUDGET_GROUP,
  NDA_VALUE,
  PROJECT_NEED_VALUE,
  PROJECT_SCOPE_VALUES,
  REFERRAL_VALUE,
} from './helper';
import { ContactUsContainer } from './ContactUsContainer';
import { EmailModal } from './EmailModal';
import './styles.css';

import BuildSomethingNewIcon from '../../../assets/svg/expand-form/build-something-new.svg';
import ImproveExistingProjectIcon from '../../../assets/svg/expand-form/improve-existing-project.svg';
import ExtendMyTeamIcon from '../../../assets/svg/expand-form/extend-my-team.svg';
import IOSIcon from '../../../assets/svg/expand-form/ios.svg';
import AndroidIcon from '../../../assets/svg/expand-form/android.svg';
import FlutterIcon from '../../../assets/svg/expand-form/flutter.svg';
import ReactNativeIcon from '../../../assets/svg/expand-form/react-native.svg';
import ProductDesignIcon from '../../../assets/svg/expand-form/product-design.svg';
import BackendIcon from '../../../assets/svg/expand-form/backend.svg';
import WebsiteIcon from '../../../assets/svg/expand-form/website.svg';
import AdminPanelIcon from '../../../assets/svg/expand-form/admin-panel.svg';

const VALID_EMAIL = /\w+([-+.']\w+)*@\w+([-.]\w+)*\.\w+([-.]\w+)*/;

const validFormInputs = (formInputs) => {
  let element;
  const inputsWithNoValidation = ['website', 'budget'];
  for (const key in formInputs) {
    element = formInputs[key];
    element = element.trim();

    if (!inputsWithNoValidation.includes(key)) {
      if (element === '') {
        return false;
      } if (key === 'email' && element !== '' && !VALID_EMAIL.test(element.toLowerCase())) {
        return false;
      }
    }
  }
  return true;
};

export const ContactUsForm = () => {
  const [showMessage, setShowMessage] = useState(false);
  const [emailSend, setEmailSend] = useState(true);
  const [dataForm, setDataForm] = useState({
    name: '',
    email: '',
    phone: '',
    website: '',
    message: '',
    nda: '',
    budget: '',
    projectNeed: '',
    projectScope: [],
    referral: '',
  });
  const [validForm, setValidForm] = useState(false);
  const [expandForm, setExpandForm] = useState(false);
  const [isLoading, setIsLoading] = useState(false);

  const [
    underFifty,
    budgetSeventyFive,
    budgetOneHundredK,
    budgetTwoHundredK,
    budgetFiveHundredK,
    aboveFiveHundredK,
  ] = BUDGET_GROUP;

  const [
    Yes,
    No,
  ] = NDA_VALUE;

  const [
    buildSomethingNew,
    improveExistingProject,
    extendMyTeam,
  ] = PROJECT_NEED_VALUE;

  const [
    iOS,
    android,
    flutter,
    reactNative,
    productDesign,
    aWebsite,
    backend,
    adminPanel,
  ] = PROJECT_SCOPE_VALUES;

  const [
    google,
    fromAFriend,
    fromACoworker,
    onlineAdvertisement,
    blogPost,
    fromANetwork,
  ] = REFERRAL_VALUE;

  const onClickSendEmail = async () => {
    setIsLoading(true);
    try {
      const response = await axios({
        method: 'post',
        url: 'https://nearshorecode.com/api/mail',
        data: {
          data: dataForm,
        },
      });
      if (response.status === 200) {
        setEmailSend(true);
      } else {
        setEmailSend(false);
      }

      setIsLoading(false);
      setShowMessage(true);
    } catch (exception) {
      setIsLoading(false);
      setShowMessage(true);
      setEmailSend(false);
    }
  };

  const onClickToggleForm = () => {
    setExpandForm(!expandForm);
  };

  const handleOkButton = () => {
    setShowMessage(false);
    setDataForm({
      name: '',
      email: '',
      phone: '',
      website: '',
      nda: '',
      message: '',
      budget: '',
      projectNeed: '',
      projectScope: [],
      referral: '',
    });
    setValidForm(false);
  };

  const handleEventChange = ({ target }) => {
    const { name: inputName } = target;
    const { value: inputValue } = target;

    setDataForm({
      ...dataForm,
      [inputName]: inputValue,
    });
  };

  const handleCheckboxChange = ({ target }) => {
    const { value: inputValue } = target;

    if (target.checked) {
      setDataForm({
        ...dataForm,
        projectScope: [...new Set([...dataForm.projectScope, inputValue])],
      });
    } else {
      setDataForm({
        ...dataForm,
        projectScope: [...dataForm.projectScope.filter((field) => field !== inputValue)],
      });
    }
  };

  const onCheckValidDataInputForm = () => {
    if (validFormInputs(dataForm)) {
      setValidForm(true);
    } else {
      setValidForm(false);
    }
  };

  const {
    name,
    email,
    phone,
    website,
    message,
    budget,
    nda,
    projectNeed,
    projectScope,
    referral,
  } = dataForm;

  const contactUsUserValues = {
    contactUsName: name,
    contactUsEmail: email,
    contactUsPhone: phone,
    contactUsWebsite: website,
    contactUsMessage: message,
    contactUsBudget: budget,
    contactUsNda: nda,
    contactUsProjectNeed: projectNeed,
    contactUsProjectScope: projectScope,
    contactUsReferral: referral,
  };

  return (
    <div id="get-in-touch" className="get-in">
      <Grid container className="get-in-container">
        <ContactUsContainer />
        <Grid item xs={12} sm={12} className="get-in-right-content">
          <Grid item className={`${!showMessage ? 'form-display' : 'form-hide'}`}>
            <form className="get-in-form" noValidate autoComplete="off">
              <Grid container className="get-in-form-container">
                <Grid item xs={12} sm={6} className="get-in-form-row">
                  <span style={{ color: '#707070', marginTop: '50px' }}>
                    Full Name
                    <span className="form-is-required">*</span>
                  </span>
                  <FormControl>
                    <TextField
                      id="name"
                      name="name"
                      size="small"
                      value={name}
                      margin="normal"
                      variant="outlined"
                      onChange={handleEventChange}
                      onKeyUp={onCheckValidDataInputForm}
                    />
                  </FormControl>
                </Grid>
                <Grid item xs={12} sm={6} className="get-in-form-row">
                  <span style={{ color: '#707070' }}>
                    Email
                    <span className="form-is-required">*</span>
                  </span>
                  <FormControl>
                    <TextField
                      id="email"
                      name="email"
                      size="small"
                      value={email}
                      margin="normal"
                      variant="outlined"
                      onChange={handleEventChange}
                      onKeyUp={onCheckValidDataInputForm}
                    />
                  </FormControl>
                </Grid>
                <Grid item xs={12} sm={6} className="get-in-form-row">
                  <span style={{ color: '#707070' }}>
                    Phone Number
                    <span className="form-is-required">*</span>
                  </span>
                  <FormControl>
                    <TextField
                      id="phone"
                      name="phone"
                      size="small"
                      value={phone}
                      margin="normal"
                      variant="outlined"
                      onChange={handleEventChange}
                      onKeyUp={onCheckValidDataInputForm}
                    />
                  </FormControl>
                </Grid>
                <div className="nda-section">
                  <span className="nda-title" style={{ color: '#707070' }}>
                    Do you need an NDA?
                    <span className="form-is-required">*</span>
                  </span>
                  <Grid item xs={12} sm={3} className="budget-form-group" style={{ padding: 0 }}>
                    <FormControl component="fieldset">
                      <RadioGroup
                        aria-label="nda"
                        name="nda"
                        value={nda}
                        onChange={handleEventChange}
                        className="budget-radio-group"
                        style={{
                          display: 'flex', flexDirection: 'row', flexWrap: 'nowrap', alignItems: 'center',
                        }}
                      >
                        <FormControlLabel value={Yes.value} control={<Radio />} label={Yes.name} />
                        <FormControlLabel value={No.value} control={<Radio />} label={No.name} />
                      </RadioGroup>
                    </FormControl>
                  </Grid>
                  <Grid item xs={12} sm={3} className="budget-group-select">
                    <FormControl className="budget-form-control">
                      <NativeSelect
                        className="budget-select"
                        value={nda}
                        name="nda"
                        onChange={handleEventChange}
                      >
                        <option value={Yes.value}>{Yes.name}</option>
                        <option value={No.value}>{No.name}</option>
                      </NativeSelect>
                    </FormControl>
                  </Grid>
                </div>
                <Grid item xs={12} sm={6} className="get-in-form-row">
                  <span style={{ color: '#707070' }}>
                    Company website
                    <span className="form-is-required">*</span>
                  </span>
                  <FormControl>
                    <TextField
                      id="website"
                      name="website"
                      size="small"
                      value={website}
                      margin="normal"
                      variant="outlined"
                      onChange={handleEventChange}
                      onKeyUp={onCheckValidDataInputForm}
                    />
                  </FormControl>
                </Grid>
                <Grid item xs={12} className="get-in-text-container">
                  <span style={{ color: '#707070' }}>
                    Describe your project
                    <span className="form-is-required">*</span>
                  </span>
                  <FormControl>
                    <TextField
                      id="message"
                      name="message"
                      size="small"
                      value={message}
                      multiline
                      rows="5"
                      margin="normal"
                      variant="outlined"
                      onChange={handleEventChange}
                      onKeyUp={onCheckValidDataInputForm}
                      required={true}
                    />
                  </FormControl>
                </Grid>
                <Grid item xs={12} className="budget-form-group">
                  <FormControl component="fieldset">
                    <FormLabel component="legend" className="budget-group-title">
                      <span style={{ color: '#707070' }}>
                        Select your budget
                        <span className="form-is-required">*</span>
                      </span>
                    </FormLabel>
                    <RadioGroup
                      aria-label="budgets"
                      name="budget"
                      value={budget}
                      onChange={handleEventChange}
                      className="scope-radio-group"
                    >
                      <FormControlLabel
                        value={underFifty.value}
                        control={<Radio style={{ display: 'none' }} />}
                        label={(
                          <div style={{
                            display: 'flex',
                            flexDirection: 'column',
                            height: '50px',
                            justifyContent: 'center',
                            alignItems: 'center',
                            border: budget === 'Under 50k' ? 'solid 2px #267a4e' : 'solid 1px #d9d7db',
                            borderRadius: '5px',
                            padding: '10px',
                          }}
                          >
                            {underFifty.name}
                          </div>
                        )}
                      />
                      <FormControlLabel
                        value={budgetSeventyFive.value}
                        control={<Radio style={{ display: 'none' }} />}
                        label={(
                          <div style={{
                            display: 'flex',
                            flexDirection: 'column',
                            height: '50px',
                            justifyContent: 'center',
                            alignItems: 'center',
                            border: budget === '50k - 75k' ? 'solid 2px #267a4e' : 'solid 1px #d9d7db',
                            borderRadius: '5px',
                            padding: '10px',
                          }}
                          >
                            {budgetSeventyFive.name}
                          </div>
                        )}
                      />
                      <FormControlLabel
                        value={budgetOneHundredK.value}
                        control={<Radio style={{ display: 'none' }} />}
                        label={(
                          <div style={{
                            display: 'flex',
                            flexDirection: 'column',
                            height: '50px',
                            justifyContent: 'center',
                            alignItems: 'center',
                            border: budget === '75k - 150k' ? 'solid 2px #267a4e' : 'solid 1px #d9d7db',
                            borderRadius: '5px',
                            padding: '10px',
                          }}
                          >
                            {budgetOneHundredK.name}
                          </div>
                        )}
                      />
                      <FormControlLabel
                        value={budgetTwoHundredK.value}
                        control={<Radio style={{ display: 'none' }} />}
                        label={(
                          <div style={{
                            display: 'flex',
                            flexDirection: 'column',
                            height: '50px',
                            justifyContent: 'center',
                            alignItems: 'center',
                            border: budget === '150k - 250k' ? 'solid 2px #267a4e' : 'solid 1px #d9d7db',
                            borderRadius: '5px',
                            padding: '10px',
                          }}
                          >
                            {budgetTwoHundredK.name}
                          </div>
                        )}
                      />
                      <FormControlLabel
                        value={budgetFiveHundredK.value}
                        control={<Radio style={{ display: 'none' }} />}
                        label={(
                          <div style={{
                            display: 'flex',
                            flexDirection: 'column',
                            height: '50px',
                            justifyContent: 'center',
                            alignItems: 'center',
                            border: budget === '250k - 500k' ? 'solid 2px #267a4e' : 'solid 1px #d9d7db',
                            borderRadius: '5px',
                            padding: '10px',
                          }}
                          >
                            {budgetFiveHundredK.name}
                          </div>
                        )}
                      />
                      <FormControlLabel
                        value={aboveFiveHundredK.value}
                        control={<Radio style={{ display: 'none' }} />}
                        label={(
                          <div style={{
                            display: 'flex',
                            flexDirection: 'column',
                            height: '50px',
                            justifyContent: 'center',
                            alignItems: 'center',
                            border: budget === 'Above 500k' ? 'solid 2px #267a4e' : 'solid 1px #d9d7db',
                            borderRadius: '5px',
                            padding: '10px',
                          }}
                          >
                            {aboveFiveHundredK.name}
                          </div>
                        )}
                      />
                    </RadioGroup>
                  </FormControl>
                </Grid>
                <Grid item xs={12} className="budget-group-select">
                  <FormControl className="budget-form-control">
                    <NativeSelect
                      className="budget-select"
                      value={budget}
                      name="budget"
                      onChange={handleEventChange}
                    >
                      <option value={underFifty.value}>{underFifty.name}</option>
                      <option value={budgetSeventyFive.value}>{budgetSeventyFive.name}</option>
                      <option value={budgetOneHundredK.value}>{budgetOneHundredK.name}</option>
                      <option value={budgetTwoHundredK.value}>{budgetTwoHundredK.name}</option>
                      <option value={budgetFiveHundredK.value}>{budgetFiveHundredK.name}</option>
                      <option value={aboveFiveHundredK.value}>{aboveFiveHundredK.name}</option>
                    </NativeSelect>
                  </FormControl>
                </Grid>
                <Grid container className="form-submit-buttons">
                  <Grid item xs={12} className="form-submit-button-title">
                    <Fab
                      variant="extended"
                      size="medium"
                      className="expand-form-button"
                      onClick={onClickToggleForm}
                    >
                      {!expandForm ? 'Answer optional questions' : 'Hide questions'}
                    </Fab>
                  </Grid>
                </Grid>
                {expandForm && (
                <>
                  <Grid item xs={12} className="budget-form-group">
                    <span className="expand-form-title">What would you like to do?</span>
                    <FormControl component="fieldset">
                      <RadioGroup
                        aria-label="projectNeed"
                        name="projectNeed"
                        value={projectNeed}
                        onChange={handleEventChange}
                        className="scope-radio-group"
                      >
                        <FormControlLabel
                          value={buildSomethingNew.value}
                          control={<Radio style={{ display: 'none' }} />}
                          label={(
                            <div
                              style={{
                                display: 'flex',
                                flexDirection: 'column',
                                height: '100px',
                                justifyContent: 'center',
                                alignItems: 'center',
                                border: projectNeed === 'Build Something New' ? 'solid 2px #267a4e' : 'solid 1px #d9d7db',
                                borderRadius: '5px',
                                width: '100%',
                              }}
                            >
                              <BuildSomethingNewIcon className={projectNeed === 'Build Something New' ? 'svgColor' : ''} />
                              {buildSomethingNew.name}
                            </div>
                            )}
                        />
                        <FormControlLabel
                          value={improveExistingProject.value}
                          control={<Radio style={{ display: 'none' }} />}
                          label={(
                            <div style={{
                              display: 'flex',
                              flexDirection: 'column',
                              height: '100px',
                              justifyContent: 'center',
                              alignItems: 'center',
                              border: projectNeed === 'Improve Existing Project' ? 'solid 2px #267a4e' : 'solid 1px #d9d7db',
                              borderRadius: '5px',
                              width: '100%',
                            }}
                            >
                              <ImproveExistingProjectIcon className={projectNeed === 'Improve Existing Project' ? 'svgColor' : ''} />
                              {improveExistingProject.name}
                            </div>
                            )}
                        />
                        <FormControlLabel
                          value={extendMyTeam.value}
                          control={<Radio style={{ display: 'none' }} />}
                          label={(
                            <div style={{
                              display: 'flex',
                              flexDirection: 'column',
                              height: '100px',
                              justifyContent: 'center',
                              alignItems: 'center',
                              border: projectNeed === 'Extend My Team' ? 'solid 2px #267a4e' : 'solid 1px #d9d7db',
                              borderRadius: '5px',
                              width: '100%',
                            }}
                            >
                              <ExtendMyTeamIcon className={projectNeed === 'Extend My Team' ? 'svgColor' : ''} />
                              {extendMyTeam.name}
                            </div>
                            )}
                        />
                      </RadioGroup>
                    </FormControl>
                  </Grid>
                  <Grid item xs={12} className="budget-group-select">
                    <FormControl className="budget-form-control">
                      <NativeSelect
                        className="budget-select"
                        value={projectNeed}
                        name="projectNeed"
                        onChange={handleEventChange}
                      >
                        <option value={buildSomethingNew.value}>{buildSomethingNew.name}</option>
                        <option value={improveExistingProject.value}>
                          {improveExistingProject.name}
                        </option>
                        <option value={extendMyTeam.value}>{extendMyTeam.name}</option>
                      </NativeSelect>
                    </FormControl>
                  </Grid>
                  <Grid item xs={12} className="budget-form-group">
                    <span className="expand-form-title">
                      What is the scope of your project?
                    </span>
                    <FormControl component="fieldset">
                      <RadioGroup
                        aria-label="projectScope"
                        name="projectScope"
                        value={projectScope}
                        onChange={handleEventChange}
                        className="scope-radio-group"
                        style={{
                          display: 'flex',
                          flexWrap: 'wrap',
                          justifyContent: 'space-around',
                          marginTop: '25px',
                          marginBottom: '15px',
                        }}
                      >
                        <FormControlLabel
                          control={<Checkbox style={{ display: 'none' }} onChange={handleCheckboxChange} value={iOS.value} />}
                          label={(
                            <div style={{
                              display: 'flex',
                              flexDirection: 'column',
                              height: '100px',
                              justifyContent: 'center',
                              alignItems: 'center',
                              border: projectScope.includes('iOS') ? 'solid 2px #267a4e' : 'solid 1px #d9d7db',
                              borderRadius: '5px',
                              padding: '10px',
                              flexBasis: '32%',
                            }}
                            >
                              <IOSIcon className={projectScope.includes('iOS') ? 'svgColor' : ''} />
                              {iOS.name}
                            </div>
                            )}
                        />
                        <FormControlLabel
                          control={<Checkbox style={{ display: 'none' }} onChange={handleCheckboxChange} value={android.value} />}
                          label={(
                            <div style={{
                              display: 'flex',
                              flexDirection: 'column',
                              height: '100px',
                              justifyContent: 'center',
                              alignItems: 'center',
                              border: projectScope.includes('Android') ? 'solid 2px #267a4e' : 'solid 1px #d9d7db',
                              borderRadius: '5px',
                              padding: '10px',
                              flexBasis: '32%',
                            }}
                            >
                              <AndroidIcon className={projectScope.includes('Android') ? 'svgColorAndroid' : ''} />
                              {android.name}
                            </div>
                            )}
                        />
                        <FormControlLabel
                          control={<Checkbox style={{ display: 'none' }} onChange={handleCheckboxChange} value={flutter.value} />}
                          label={(
                            <div style={{
                              display: 'flex',
                              flexDirection: 'column',
                              height: '100px',
                              justifyContent: 'center',
                              alignItems: 'center',
                              border: projectScope.includes('Flutter') ? 'solid 2px #267a4e' : 'solid 1px #d9d7db',
                              borderRadius: '5px',
                              padding: '10px',
                              flexBasis: '32%',
                            }}
                            >
                              <FlutterIcon className={projectScope.includes('Flutter') ? 'svgColor' : ''} />
                              {flutter.name}
                            </div>
                            )}
                        />
                        <FormControlLabel
                          control={<Checkbox style={{ display: 'none' }} onChange={handleCheckboxChange} value={reactNative.value} />}
                          label={(
                            <div style={{
                              display: 'flex',
                              flexDirection: 'column',
                              height: '100px',
                              justifyContent: 'center',
                              alignItems: 'center',
                              border: projectScope.includes('React Native') ? 'solid 2px #267a4e' : 'solid 1px #d9d7db',
                              borderRadius: '5px',
                              padding: '10px',
                              flexBasis: '32%',
                            }}
                            >
                              <ReactNativeIcon className={projectScope.includes('React Native') ? 'svgColor' : ''} />
                              {reactNative.name}
                            </div>
                            )}
                        />
                        <FormControlLabel
                          control={<Checkbox style={{ display: 'none' }} onChange={handleCheckboxChange} value={productDesign.value} />}
                          label={(
                            <div style={{
                              display: 'flex',
                              flexDirection: 'column',
                              height: '100px',
                              justifyContent: 'center',
                              alignItems: 'center',
                              border: projectScope.includes('Product Design') ? 'solid 2px #267a4e' : 'solid 1px #d9d7db',
                              borderRadius: '5px',
                              padding: '10px',
                              flexBasis: '32%',
                            }}
                            >
                              <ProductDesignIcon className={projectScope.includes('Product Design') ? 'svgColor' : ''} />
                              {productDesign.name}
                            </div>
                            )}
                        />
                        <FormControlLabel
                          control={<Checkbox style={{ display: 'none' }} onChange={handleCheckboxChange} value={backend.value} />}
                          label={(
                            <div style={{
                              display: 'flex',
                              flexDirection: 'column',
                              height: '100px',
                              justifyContent: 'center',
                              alignItems: 'center',
                              border: projectScope.includes('Backend') ? 'solid 2px #267a4e' : 'solid 1px #d9d7db',
                              borderRadius: '5px',
                              padding: '10px',
                              flexBasis: '32%',
                            }}
                            >
                              <BackendIcon className={projectScope.includes('Backend') ? 'svgColor' : ''} />
                              {backend.name}
                            </div>
                            )}
                        />
                        <FormControlLabel
                          control={<Checkbox style={{ display: 'none' }} onChange={handleCheckboxChange} value={aWebsite.value} />}
                          label={(
                            <div style={{
                              display: 'flex',
                              flexDirection: 'column',
                              height: '100px',
                              justifyContent: 'center',
                              alignItems: 'center',
                              border: projectScope.includes('Website') ? 'solid 2px #267a4e' : 'solid 1px #d9d7db',
                              borderRadius: '5px',
                              padding: '10px',
                              flexBasis: '32%',
                            }}
                            >
                              <WebsiteIcon className={projectScope.includes('Website') ? 'svgColor' : ''} />
                              {aWebsite.name}
                            </div>
                            )}
                        />
                        <FormControlLabel
                          control={<Checkbox style={{ display: 'none' }} onChange={handleCheckboxChange} value={adminPanel.value} />}
                          label={(
                            <div style={{
                              display: 'flex',
                              flexDirection: 'column',
                              height: '100px',
                              justifyContent: 'center',
                              alignItems: 'center',
                              border: projectScope.includes('Admin Panel') ? 'solid 2px #267a4e' : 'solid 1px #d9d7db',
                              borderRadius: '5px',
                              padding: '10px',
                              flexBasis: '32%',
                            }}
                            >
                              <AdminPanelIcon className={projectScope.includes('Admin Panel') ? 'svgColor' : ''} />
                              {adminPanel.name}
                            </div>
                            )}
                        />
                      </RadioGroup>
                    </FormControl>
                  </Grid>
                  <Grid item xs={12} className="budget-group-select">
                    <FormControl className="budget-form-control">
                      <NativeSelect
                        className="budget-select"
                        value={projectScope}
                        name="projectScope"
                        onChange={handleEventChange}
                      >
                        <option value={iOS.value}>{iOS.name}</option>
                        <option value={android.value}>{android.name}</option>
                        <option value={flutter.value}>{flutter.name}</option>
                        <option value={reactNative.value}>{reactNative.name}</option>
                        <option value={productDesign.value}>{productDesign.name}</option>
                        <option value={backend.value}>{backend.name}</option>
                        <option value={adminPanel.value}>{adminPanel.name}</option>
                      </NativeSelect>
                    </FormControl>
                  </Grid>
                  <Grid item xs={12} className="budget-form-group">
                    <span className="expand-form-title">
                      How did you hear about us?
                    </span>
                    <FormControl component="fieldset">
                      <RadioGroup
                        aria-label="referral"
                        name="referral"
                        value={referral}
                        onChange={handleEventChange}
                        className="scope-radio-group"
                      >
                        <FormControlLabel
                          value={google.value}
                          control={<Radio style={{ display: 'none' }} />}
                          label={(
                            <div style={{
                              display: 'flex',
                              flexDirection: 'column',
                              height: '50px',
                              justifyContent: 'center',
                              alignItems: 'center',
                              border: referral === 'Google' ? 'solid 2px #267a4e' : 'solid 1px #d9d7db',
                              borderRadius: '5px',
                              padding: '10px',
                            }}
                            >
                              {google.name}
                            </div>
                            )}
                        />
                        <FormControlLabel
                          value={fromAFriend.value}
                          control={<Radio style={{ display: 'none' }} />}
                          label={(
                            <div style={{
                              display: 'flex',
                              flexDirection: 'column',
                              height: '50px',
                              justifyContent: 'center',
                              alignItems: 'center',
                              border: referral === 'From a friend' ? 'solid 2px #267a4e' : 'solid 1px #d9d7db',
                              borderRadius: '5px',
                              padding: '10px',
                            }}
                            >
                              {fromAFriend.name}
                            </div>
                            )}
                        />
                        <FormControlLabel
                          value={fromACoworker.value}
                          control={<Radio style={{ display: 'none' }} />}
                          label={(
                            <div style={{
                              display: 'flex',
                              flexDirection: 'column',
                              height: '50px',
                              justifyContent: 'center',
                              alignItems: 'center',
                              border: referral === 'From a coworker' ? 'solid 2px #267a4e' : 'solid 1px #d9d7db',
                              borderRadius: '5px',
                              padding: '10px',
                            }}
                            >
                              {fromACoworker.name}
                            </div>
                            )}
                        />
                        <FormControlLabel
                          value={onlineAdvertisement.value}
                          control={<Radio style={{ display: 'none' }} />}
                          label={(
                            <div style={{
                              display: 'flex',
                              flexDirection: 'column',
                              height: '50px',
                              justifyContent: 'center',
                              alignItems: 'center',
                              border: referral === 'Online Advertisement' ? 'solid 2px #267a4e' : 'solid 1px #d9d7db',
                              borderRadius: '5px',
                              padding: '10px',
                            }}
                            >
                              {onlineAdvertisement.name}
                            </div>
                            )}
                        />
                        <FormControlLabel
                          value={blogPost.value}
                          control={<Radio style={{ display: 'none' }} />}
                          label={(
                            <div style={{
                              display: 'flex',
                              flexDirection: 'column',
                              height: '50px',
                              justifyContent: 'center',
                              alignItems: 'center',
                              border: referral === 'Blog Post' ? 'solid 2px #267a4e' : 'solid 1px #d9d7db',
                              borderRadius: '5px',
                              padding: '10px',
                            }}
                            >
                              {blogPost.name}
                            </div>
                            )}
                        />
                        <FormControlLabel
                          value={fromANetwork.value}
                          control={<Radio style={{ display: 'none' }} />}
                          label={(
                            <div style={{
                              display: 'flex',
                              flexDirection: 'column',
                              height: '50px',
                              justifyContent: 'center',
                              alignItems: 'center',
                              border: referral === 'From a network' ? 'solid 2px #267a4e' : 'solid 1px #d9d7db',
                              borderRadius: '5px',
                              padding: '10px',
                            }}
                            >
                              {fromANetwork.name}
                            </div>
                            )}
                        />
                      </RadioGroup>
                    </FormControl>
                  </Grid>
                  <Grid item xs={12} className="budget-group-select">
                    <FormControl className="budget-form-control">
                      <NativeSelect
                        className="budget-select"
                        value={referral}
                        name="referral"
                        onChange={handleEventChange}
                      >
                        <option value={google.value}>{google.name}</option>
                        <option value={fromAFriend.value}>{fromAFriend.name}</option>
                        <option value={fromACoworker.value}>{fromACoworker.name}</option>
                        <option
                          value={onlineAdvertisement.value}
                        >
                          {onlineAdvertisement.name}
                        </option>
                        <option value={blogPost.value}>{blogPost.name}</option>
                      </NativeSelect>
                    </FormControl>
                  </Grid>
                </>
                )}
              </Grid>
            </form>
          </Grid>
        </Grid>
        {!showMessage ? null : <EmailModal handleClose={handleOkButton} emailSend={emailSend} userValues={contactUsUserValues} />}
      </Grid>
      {
        showMessage ? null : (
          <div className={`get-in-submit-btn ${!validForm ? 'btn-disabled' : ''} ${isLoading ? 'btn-disabled' : ''}`}>
            <Grid container className="form-submit-buttons">
              <Grid item xs={12} className="form-submit-button-title">
                <Fab
                  variant="extended"
                  size="medium"
                  className="submit-button"
                  onClick={onClickSendEmail}
                >
                  SUBMIT
                </Fab>
              </Grid>
            </Grid>
          </div>
        )
      }
    </div>
  );
};

// const {
//   name,
//   email,
//   phone,
//   website,
//   message,
//   budget,
//   nda,
//   projectNeed,
//   projectScope,
//   referral,
// } = dataForm;
