const compareValueMemo = (prevProp, nextProp) => {
  const { id: idPrevious, name: namePrevious } = prevProp;
  const { id: idNext, name: nameNext } = nextProp;
  if (idPrevious === idNext && namePrevious === nameNext) {
    return true;
  }
  return false;
};

export { compareValueMemo };
